<template>
  <div class="page">
    <b-card>
      <div
        class="row justify-content-between"
        style="padding: 0 1.5rem"
      >
        <div class="mb-1">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="$router.push({ name: 'create-residential' })"
          >
            <feather-icon icon="PlusIcon" />
            Add New Listing
          </b-button>
          <b-button
            v-b-modal.modal-import
            variant="outline-secondary"
          >
            Import CSV
          </b-button>
        </div>
        <b-input-group
          class="input-group-merge mb-1"
          style="max-width: 300px; float: right"
        >
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="search"
            placeholder="Search by address or MLS Id"
            @input="onSearch"
          />
        </b-input-group>
      </div>

      <b-tabs
        class="mt-2"
      >
        <div class="d-flex mb-1 align-items-end justify-content-between">
          <div>
            <b-dropdown
              id="dropdown-1"
              :text="'Sort by: ' + getCurrentSortOption"
              variant="outline-primary"
            >
              <b-dropdown-item
                :variant="currentOrderBy === 'price_asc' ? 'primary': ''"
                @click="onSortItemClick('price_asc')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  Price: Lowest to Highest
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                :variant="currentOrderBy === 'price_desc' ? 'primary': ''"
                @click="onSortItemClick('price_desc')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  Price: Highest to Lowest
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                :variant="currentOrderBy === 'date_desc' ? 'primary': ''"
                @click="onSortItemClick('date_desc')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  List Date: Newest to Oldest
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                :variant="currentOrderBy === 'date_asc' ? 'primary': ''"
                @click="onSortItemClick('date_asc')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  List Date: Oldest to Newest
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                :variant="selectedSource === 'RETS' ? 'primary': ''"
                @click="onSortBySource('RETS')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  Only RETS
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                :variant="selectedSource === 'Exclusive' ? 'primary': ''"
                @click="onSortBySource('Exclusive')"
              >
                <div class="d-flex align-items-center justify-content-between">
                  Only Exclusive
                </div>
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              v-b-modal.modal-delete-listing
              variant="outline-danger"
              class="btn-icon ml-1"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>

          <div class="d-flex flex-column align-items-end">
            <span>{{ getTotalNumofListings }}</span>
            <span>{{ getNumOfListings }}</span>
          </div>
        </div>
        <b-tab
          title="Active"
          active
          lazy
          @click="onTabClick('active')"
        >
          <div class="row">
            <!-- listings.Active -->
            <div
              v-for="(listing, index) in getListingsPerPage"
              :key="index"
              class="col-lg-4 col-md-6 col-sm-12 col-xs-12"
            >
              <listingCard
                :listing="listing"
                path="residential"
                @on-checkbox-update="onCheckBoxChanged"
                @on-delete-listing="onDeleteListing"
              />
            </div>
          </div>

          <div v-if="!isListingFound">
            <b-alert
              class="p-2"
              show
              variant="warning"
            >
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              {{ errMsg }}
            </b-alert>
          </div>
        </b-tab>
        <b-tab
          title="Sold"
          lazy
          @click="onTabClick('sold')"
        >
          <div class="row">
            <div
              v-for="(listing, index) in getListingsPerPage"
              :key="index"
              class="col-lg-4 col-md-6 col-sm-12 col-xs-12"
            >
              <listingCard
                :listing="listing"
                path="residential"
                @on-checkbox-update="onCheckBoxChanged"
                @on-delete-listing="onDeleteListing"
              />
            </div>
          </div>

          <div v-if="!isListingFound">
            <b-alert
              class="p-2"
              show
              variant="warning"
            >
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              {{ errMsg }}
            </b-alert>
          </div>
        </b-tab>

        <b-tab
          title="Expired"
          lazy
          @click="onTabClick('expired')"
        ><div class="row">
           <div
             v-for="(listing, index) in getListingsPerPage"
             :key="index"
             class="col-lg-4 col-md-6 col-sm-12 col-xs-12"
           >
             <listingCard
               :listing="listing"
               path="residential"
               @on-checkbox-update="onCheckBoxChanged"
               @on-delete-listing="onDeleteListing"
             />
           </div>
         </div>
          <div v-if="!isListingFound">
            <b-alert
              class="p-2"
              show
              variant="warning"
            >
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              {{ errMsg }}
            </b-alert>
          </div></b-tab>

        <b-tab
          title="Terminated"
          lazy
          @click="onTabClick('terminated')"
        ><div class="row">
           <div
             v-for="(listing, index) in getListingsPerPage"
             :key="index"
             class="col-lg-4 col-md-6 col-sm-12 col-xs-12"
           >
             <listingCard
               :listing="listing"
               path="residential"
               @on-checkbox-update="onCheckBoxChanged"
               @on-delete-listing="onDeleteListing"
             />
           </div>
         </div>
          <div v-if="!isListingFound">
            <b-alert
              class="p-2"
              show
              variant="warning"
            >
              <feather-icon
                class="mr-1"
                icon="AlertOctagonIcon"
              />
              {{ errMsg }}
            </b-alert>
          </div>
        </b-tab>
      </b-tabs>

      <b-pagination
        v-if="isListingFound"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="rows"
        align="center"
        @change="onPageChange"
      />

    </b-card>
    <b-modal
      id="modal-import"
      cancel-variant="outline-secondary"
      ok-title="Import"
      cancel-title="Close"
      centered
      title="Import Listings from CSV"
      @ok="importCsv"
    >
      <b-form-file
        v-model="importFile"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      />
    </b-modal>

    <b-modal
      id="modal-delete-listing"
      cancel-variant="outline-secondary"
      ok-title="Delete"
      ok-variant="outline-danger"
      cancel-title="Cancel"
      centered
      title="Delete listings"
      @ok="onListingDeleteClick"
    >
      Are you sure?
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BButton,
  BTabs,
  BTab,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BFormFile,
  BPagination,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
// import state from '@/store/state'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import listingCard from '../components/listingCard.vue'

export default {
  components: {
    BAlert,
    BCard,
    BButton,
    BTabs,
    BTab,
    BInputGroup,
    listingCard,
    BFormInput,
    BInputGroupPrepend,
    BFormFile,
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 4,
      rows: 12,
      importFile: [],
      search: '',
      file: '',
      csvData: [],
      listings: {
        Active: [],
        Sold: [],
        Expired: [],
        Terminated: [],
      },
      lastSortOption: {
        active: '',
        sold: '',
        expired: '',
        terminated: '',
      },
      currentTab: 'active',
      currentOrderBy: 'date_desc',
      selectedSource: '',
      isListingFound: false,
      errMsg: 'Listings could not be found',
      targetMlsArr: [],
    }
  },
  computed: {
    getCurrentSortOption() {
      let current = ''
      switch (this.currentOrderBy) {
        case 'price_asc':
          current = 'Price Lowest to Highest'
          break
        case 'price_desc':
          current = 'Price Highest to Lowest'
          break
        case 'date_asc':
          current = 'Date Oldeset to Newest'
          break
        case 'date_desc':
          current = 'Date Newest to Oldest'
          break
        default:
          break
      }

      if (this.selectedSource !== '') {
        current += ' / ' + this.selectedSource
      }

      return current
    },
    getNumOfListings() {
      const upper = this.currentTab.charAt(0).toUpperCase() + this.currentTab.slice(1)

      let start = 0
      let end = 0

      if (this.search !== '') {
        start = this.currentPage !== 1 ? 12 * (this.currentPage - 1) + 1 : 1
        end = this.getSearchedListing.length < 12 * this.currentPage ? this.getSearchedListing.length : 12 * this.currentPage

        if (this.getSearchedListing.length === 0 || !this.getSearchedListing.length) {
          start = 0
          end = 0
        }

        if (this.selectedSource !== '') {
          const temp = this.getSearchedListing.filter(data => data.source === this.selectedSource)
          end = temp.length < 12 * this.currentPage ? temp.length : 12 * this.currentPage

          if (temp.length === 0 || !temp.length) {
            start = 0
            end = 0
          }
        }
      } else {
        start = this.currentPage !== 1 ? 12 * (this.currentPage - 1) + 1 : 1
        end = this.listings[upper].length < 12 * this.currentPage ? this.listings[upper].length : 12 * this.currentPage

        if (this.listings[upper].length === 0) {
          start = 0
        }

        if (this.selectedSource !== '') {
          const temp = this.listings[upper].filter(data => data.source === this.selectedSource)
          end = temp.length < 12 * this.currentPage ? temp.length : 12 * this.currentPage

          if (temp.length === 0) {
            start = 0
          }
        }
      }

      return start + ' to ' + end
    },
    getTotalNumofListings() {
      const upper = this.currentTab.charAt(0).toUpperCase() + this.currentTab.slice(1)

      if (this.search !== '') {
        if (this.selectedSource !== '') {
          const temp = this.getSearchedListing.filter(data => data.source === this.selectedSource)

          if (temp.length) {
            return 'Total: ' + temp.length
          }
        }

        if (this.getSearchedListing.length) {
          return 'Total: ' + this.getSearchedListing.length
        }

        return 'Total: 0'
      }

      if (this.selectedSource !== '') {
        const temp = this.listings[upper].filter(data => data.source === this.selectedSource)
        return 'Total: ' + temp.length
      }

      return 'Total: ' + this.listings[upper].length
    },
    getSearchedListing() {
      let temp = {}

      const upper = this.currentTab.charAt(0).toUpperCase() + this.currentTab.slice(1)

      if (this.listings[upper].length > 0) {
        if (this.search !== '') {
          temp = this.listings[upper].filter(data => (data.mls_id !== null ? data.mls_id.includes(this.search) || data.address.toLowerCase().includes(this.search.toLowerCase()) : data.address.toLowerCase().includes(this.search.toLowerCase())))

          if (this.selectedSource !== '') {
            temp = temp.filter(data => data.source === this.selectedSource)
          }
        }
      }

      return temp
    },
    getListingsPerPage() {
      let temp = {}

      const upper = this.currentTab.charAt(0).toUpperCase() + this.currentTab.slice(1)

      if (this.listings[upper].length) {
        if (this.search !== '') {
          temp = this.listings[upper].filter(data => (data.mls_id !== null ? data.mls_id.includes(this.search) || data.address.toLowerCase().includes(this.search.toLowerCase()) : data.address.toLowerCase().includes(this.search.toLowerCase())))

          if (this.selectedSource !== '') {
            temp = temp.filter(data => data.source === this.selectedSource)
          }
        } else {
          temp = this.listings[upper]

          if (this.selectedSource !== '') {
            temp = temp.filter(data => data.source === this.selectedSource)
          }
        }
      }

      return this.listings[upper].length && temp.slice((this.currentPage - 1) * 12, this.currentPage * 12)
    },
  },
  created() {
    const data = {
      status: [this.currentTab],
      orderBy: this.currentOrderBy,
    }

    this.$store.dispatch('listings/getResidentiaListings', data).then(response => {
      if (response.code === 200) {
        const upper = this.currentTab.charAt(0).toUpperCase() + this.currentTab.slice(1)
        Object.keys(response.data).forEach(key => {
          this.listings[upper].push(response.data[key])
        })
        this.rows = Math.ceil(this.listings.Active.length / 12) * 4
      } else if (response.code === 404) {
        this.isListingFound = false
        this.errMsg = response.message
      }
    }).catch(err => {
      console.error(err)
    })
  },
  updated() {
    this.setRow()
  },
  methods: {
    onDeleteListing(targetListing) {
      const targetStatus = targetListing.status
      const targetId = targetListing.system_id

      this.listings[targetStatus] = this.listings[targetStatus].filter(data => data.system_id !== null && data.system_id !== targetId)
    },
    formatDwelType(type) {
      let converted
      switch (type) {
        case 'TWNHS':
          converted = 'Townhouse'
          break
        case 'APTU':
          converted = 'Apartment/Condo'
          break
        case 'HOUSE':
          converted = 'House/Single Family'
          break
        default:
          converted = 'House with Acreage'
          break
      }

      return converted
    },
    // TODO: csv 받아서 status에 맞게 수정, 지금은 temp
    getStatus(status) {
      let converted
      switch (status) {
        case 'A':
          converted = 'Active'
          break
        case 'S':
          converted = 'Sold'
          break
        case 'E':
          converted = 'Expired'
          break
        case 'T':
          converted = 'Terminated'
          break
        default:
          converted = 'Active'
          break
      }

      return converted
    },
    getCsvListedDate(date) {
      console.log(date)
      const dateArr = date.split('/')
      if (dateArr.length !== 3) {
        return null
      }
      console.log(dateArr)

      const month = dateArr[0].length === 1 ? '0' + dateArr[0] : dateArr[0]
      const day = dateArr[1].length === 1 ? '0' + dateArr[1] : dateArr[1]
      const year = dateArr[2]

      return year + '-' + month + '-' + day
    },
    removeComma(val) {
      // eslint-disable-next-line comma-spacing
      return val.replace(/,/g,'')
    },
    convertToIntFormat(price) {
      let intPrice = price.substring(1)
      // eslint-disable-next-line comma-spacing
      intPrice = intPrice.replace(/,/g,'')
      return intPrice
    },
    onSortBySource(source) {
      if (source === this.selectedSource) {
        this.selectedSource = ''
      } else {
        this.selectedSource = source
      }
    },
    onSortItemClick(order) {
      if (this.currentOrderBy !== order) {
        this.currentOrderBy = order

        const data = {
          status: [this.currentTab],
          orderBy: order,
        }

        const upper = this.currentTab.charAt(0).toUpperCase() + this.currentTab.slice(1)
        this.listings[upper] = []

        this.$store.dispatch('listings/getResidentiaListings', data).then(response => {
          if (response.code === 200) {
            this.listings[upper] = []
            Object.keys(response.data).forEach(key => {
              this.listings[upper].push(response.data[key])
            })

            this.setRow()
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    onPageChange() {
      window.scrollTo(0, 0)
    },
    onSearch() {
      this.currentPage = 1
      let pageNum = 0

      const upper = this.currentTab.charAt(0).toUpperCase() + this.currentTab.slice(1)

      if (this.search !== '') {
        pageNum = Math.ceil(this.getSearchedListing.length / 12)
      } else {
        pageNum = Math.ceil(this.listings[upper].length / 12)
      }

      this.rows = pageNum * 4
    },
    setRow() {
      let pageNum = 0
      const upper = this.currentTab.charAt(0).toUpperCase() + this.currentTab.slice(1)
      if (this.search === '') {
        pageNum = Math.ceil(this.listings[upper].length / 12)

        if (this.selectedSource !== '') {
          const temp = this.listings[upper].filter(data => data.source === this.selectedSource)
          pageNum = Math.ceil(temp.length / 12)
        }
      } else {
        pageNum = Math.ceil(this.getSearchedListing.length / 12)

        if (this.selectedSource !== '') {
          const temp = this.getSearchedListing.filter(data => data.source === this.selectedSource)
          pageNum = Math.ceil(temp.length / 12)
        }
      }
      this.rows = pageNum * 4

      if (pageNum > 0) {
        this.isListingFound = true
      } else {
        this.isListingFound = false
      }

      this.lastSortOption[this.currentTab] = this.currentOrderBy
    },
    importCsv() {
      const vm = this
      vm.csvData = []
      const reader = new FileReader()
      reader.onload = e => {
        console.log(e.target.result)
        vm.file = vm.csvJSON(e.target.result)
      }
      reader.readAsText(this.importFile)
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
    csvJSON(csv) {
      const line = csv.split('\n')
      const header = line[0].split(',')
      console.log('header', header)
      line.shift()
      console.log(line)
      line.forEach(element => {
        const temp = {}
        if (element) {
          console.log(element)
          // let newElement = element.replaceAll(',', '')
          // newElement = newElement.replaceAll('$', '')
          const columns = element.split(',')
          console.log(columns)
          columns.forEach((col, index) => {
            if (header[index]) {
              let label = header[index].replace(/['"]+/g, '')
              label = label.replace(/\s/g, '_')
              const val = col.replace(/['"]+/g, '')

              if (label === 'Address') {
                temp.address = val
              } else if (label === 'Area') {
                temp.area = val
              } else if (label === 'City') {
                temp.city = val
              } else if (label === 'Floor_Area_-Grand_Total') {
                temp.floor_size = this.removeComma(val)
              } else if (label === 'List_Date') {
                console.log('list', val)
                temp.list_date = this.getCsvListedDate(val)
              } else if (label === 'List_Price' || label === 'Price') {
                temp.asking_price = this.convertToIntFormat(val)
              } else if (label === 'Sold_Price') {
                temp.sold_price = this.convertToIntFormat(val)
              } else if (label === 'Sold_Date') {
                console.log('sold')
                temp.sold_date = this.getCsvListedDate(val)
              } else if (label === 'ML_#') {
                temp.mls_id = val
              } else if (label === 'Prop_Type') {
                temp.property_type = val
              } else if (label === 'Public_Remarks') {
                temp.remark = val
              } else if (label === 'S/A') {
                temp.subarea = val
              } else if (label === 'Status') {
                temp.status = val
              } else if (label === 'Total_Baths_') {
                temp.bathrooms = val
              } else if (label === 'Total_Bedrooms' || label === 'Tot BR') {
                temp.bedrooms = val
              } else if (label === 'Type') {
                temp.dwelling_type = val
                temp.type = val
              } else if (label === 'Yr_Blt' || label === 'Yr Blt') {
                temp.year_built = val
              } else if (label === 'Pics') {
                temp.photo_url = val
              } else if (label === 'PicCount') {
                temp.photos_count = val
              } else if (label === 'Postal_Code') {
                temp.postal_code = val
              }
            }
          })
          this.csvData.push(temp)
          temp.style_of_home = ''
          temp.lot_size_sqft = ''
          temp.address_unit = ''
          temp.address_number = ''
          temp.address_street = ''
          temp.address_street_designation_id = ''
          temp.address_direction = ''
          temp.province = ''
          temp.postal_code = ''
        }
      })
      this.$store.dispatch('listings/importResidentialCsv', { data: this.csvData }).then(response => {
        if (response.code === 200) {
          // this.$emit('on-delete-listing', this.listing)
          this.showToast('Listing Added', 'CheckCircleIcon', 'Listings have been added successfully', 'success', 'bottom-right')
        } else {
          this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
        }
      }).catch(err => {
        console.error(err)
      })
    },
    // csvJSON(csv) {
    //   const line = csv.split('\n')
    //   const header = line[0].split('","')
    //   line.shift()
    //   // console.log('header', header)
    //   console.log('line', line)
    //   line.forEach(element => {
    //     if (element) {
    //       const columns = element.split('","')
    //       console.log('columns', columns[0])
    //       const temp = {}
    //       columns[0].forEach((col, index) => {
    //         let label = header[index].replace(/['"]+/g, '')
    //         label = label.replace(/\s/g, '_')
    //         const val = col.replace(/['"]+/g, '')
    //         switch (label) {
    //           case 'Address':
    //             label = 'address'
    //             temp[label] = val
    //             console.log('address')
    //             break
    //           case 'Area':
    //             label = 'area'
    //             temp[label] = val
    //             break
    //           case 'City':
    //             label = 'city'
    //             temp[label] = val
    //             break
    //           case 'Floor_Area_-Grand_Total':
    //             label = 'floor_size'
    //             temp[label] = this.removeComma(val)
    //             break
    //           case 'List_Date':
    //             label = 'list_date'
    //             temp[label] = this.getCsvListedDate(val)
    //             break
    //           case 'List_Price':
    //             label = 'asking_price'
    //             temp[label] = this.convertToIntFormat(val)
    //             break
    //           case 'Sold_Price':
    //             label = 'sold_price'
    //             temp[label] = this.convertToIntFormat(val)
    //             break
    //           case 'Sold_Date':
    //             label = 'sold_date'
    //             temp[label] = this.getCsvListedDate(val)
    //             break
    //           case 'ML_#':
    //             label = 'mls_id'
    //             temp[label] = val
    //             break
    //           case 'Prop_Type':
    //             label = 'property_type'
    //             temp[label] = val
    //             break
    //           case 'Public_Remarks':
    //             label = 'remark'
    //             temp[label] = val
    //             break
    //           case 'S/A':
    //             label = 'subarea'
    //             temp[label] = val
    //             break
    //           case 'Status':
    //             label = 'status'
    //             temp[label] = this.getStatus(val)
    //             break
    //           case 'Total_Baths_':
    //             label = 'bathrooms'
    //             temp[label] = val
    //             break
    //           case 'Total_Bedrooms':
    //             label = 'bedrooms'
    //             temp[label] = val
    //             break
    //           case 'TypeDwel':
    //             label = 'dwelling_type'
    //             temp[label] = this.formatDwelType(val)
    //             break
    //           case 'Yr_Blt':
    //             label = 'year_built'
    //             temp[label] = val
    //             break
    //           case 'Pics':
    //             label = 'photo_url'
    //             temp[label] = val
    //             break
    //           case 'PicCount':
    //             label = 'photos_count'
    //             temp[label] = val
    //             break
    //           default:
    //             break
    //         }
    //       })

    //       temp.style_of_home = ''
    //       temp.lot_size_sqft = ''
    //       temp.address_unit = ''
    //       temp.address_number = ''
    //       temp.address_street = ''
    //       temp.address_street_designation_id = ''
    //       temp.address_direction = ''
    //       temp.province = ''
    //       temp.postal_code = ''

    //       this.csvData.push(temp)
    //     }
    //   })
    //   console.log(this.csvData)
    //   this.$store.dispatch('listings/importResidentialCsv', { data: this.csvData }).then(response => {
    //     if (response.code === 200) {
    //       // this.$emit('on-delete-listing', this.listing)
    //       this.showToast('Listing Added', 'CheckCircleIcon', 'Listings have been added successfully', 'success', 'bottom-right')
    //     } else {
    //       this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
    //     }
    //   }).catch(err => {
    //     console.error(err)
    //   })
    // },
    onTabClick(type) {
      this.currentPage = 1
      this.currentTab = type

      const data = {
        status: [this.currentTab],
        orderBy: this.currentOrderBy,
      }

      const upper = this.currentTab.charAt(0).toUpperCase() + this.currentTab.slice(1)

      // 같은 sorting option이면 requeset 안보냄
      if (this.lastSortOption[this.currentTab] !== this.currentOrderBy) {
        this.listings[upper] = []
        this.$store.dispatch('listings/getResidentiaListings', data).then(response => {
          if (response.code === 200) {
            Object.keys(response.data).forEach(key => {
              this.listings[upper].push(response.data[key])
            })
            this.isListingFound = true
            this.setRow()
          } else if (response.code === 404) {
            this.isListingFound = false
            this.errMsg = response.message
          }
        }).catch(err => {
          console.error(err)
        })
      } else {
        this.setRow()
      }
    },
    onCheckBoxChanged(data) {
      const { system_id, isSelected, type } = data

      if (isSelected) {
        this.targetMlsArr.push({ system_id, type })
      } else {
        // eslint-disable-next-line camelcase
        const targetIndex = this.targetMlsArr.findIndex(i => i.system_id === system_id)
        this.targetMlsArr.splice(targetIndex, 1)
      }
    },
    onListingDeleteClick() {
      console.log(this.targetMlsArr)

      if (this.targetMlsArr.length > 0) {
        this.$store.dispatch('listings/deleteResidential', { listings: this.targetMlsArr }).then(response => {
          if (response.code === 200) {
            if (response.data.completed && response.data.completed.length) {
              response.data.completed.forEach(listing => {
                const targetStatus = listing.status
                const targetId = listing.system_id

                this.listings[targetStatus] = this.listings[targetStatus].filter(data => data.system_id !== null && data.system_id !== targetId)
                this.showToast('Listing Deleted', 'CheckCircleIcon', 'Listing with MLS ' + listing.mls_id + ' has been deleted successfully', 'success', 'bottom-right')
              })
            }

            if (response.data.failed && response.data.failed.length) {
              response.data.failed.forEach(listing => {
                this.showToast('Failed', 'AlertCircleIcon', 'Listing ' + listing.listing + ' - ' + listing.message + '"', 'danger', 'bottom-right')
              })
            }

          // this.$emit('on-delete-listing', this.listing)
          // this.showToast('Listing Deleted', 'CheckCircleIcon', 'Listing has been deleted successfully', 'success', 'bottom-right')
          } else {
            this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      } else {
        this.showToast('Failed', 'AlertCircleIcon', 'Listing should be selected at least one', 'danger', 'bottom-right')
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
